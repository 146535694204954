<template>
  <div>
    <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
    <div v-for="(item, index) in items" :key="index">
      <div class="card">
        <div class="h4 pl-1 pr-1 pt-1">{{ item.topic }}</div>

        <Gallery class="pb-1" :images="item.urlImage" />
        <p class="card-text pl-1 pr-1">
          {{ item.detail }}
        </p>
        <div class="d-flex justify-content-between flex-wrap pl-1 pr-1">
          <div class="flex-wrap">
            <small class="text-muted">
              Create: <cite>{{ formatToThaiTime(item.showDate) }}</cite>
            </small>
          </div>
          <div class="flex-wrap">
            <small class="text-muted">
              Update: <cite>{{ formatTofromNow(item.registerDate) }}</cite>
            </small>
          </div>
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <a :href="item.weblink != '-' ? item.weblink : ''" target="_blank"><feather-icon icon="MousePointerIcon" class="mr-25" /> Web Link</a>
          </div>
          <a
            target="_blank"
            class="list-group-item text-primary"
            href="/map-single"
            @click="
              () => {
                $store.dispatch('addStates', item);
              }
            "
          >
            <a><feather-icon icon="MapPinIcon" class="mr-25" />GPS</a>
          </a>
          <div class="list-group-item text-primary">
            <a><feather-icon icon="DownloadIcon" class="mr-25" />File Dowload</a>
          </div>
          <div class="list-group-item text-primary">
            <a><feather-icon icon="UploadIcon" class="mr-25" />File Upload</a>
          </div>
        </div>

        <div class="d-flex m-1">
          <div v-for="(tags, index) in item.tag" :key="index" class="card-text">
            <b-badge class="col-auto" variant="primary">
              <feather-icon icon="HashIcon" class="mr-25" />
              <span>{{ tags }}</span>
            </b-badge>
          </div>
        </div>

        <div class="pl-1 pr-1 pb-3">
          <small class="text-muted">
            ผู้ส่ง: <cite>{{ lineName }}</cite>
          </small>
          <b-form-textarea id="textarea-default" placeholder="Comment" rows="3" />
          <b-button variant="success" class="mt-1">ส่ง </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Gallery from '../Components/Gallery.vue';

  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  export default {
    params: true,
    components: {
      Gallery,
    },
    data() {
      return {
        items: [],
        playLoad: true,
        lineName: '',
      };
    },
    created() {
      const { lineName } = JSON.parse(localStorage.getItem('profile_visitor'));
      this.lineName = lineName;
      this.searchDataTable();
    },
    methods: {
      async searchDataTable() {
        this.playLoad = true;
        const topic = this.$route.params.topic;
        if (topic) {
          const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
          this.$store
            .dispatch('getData', `announce/${userId}?topic=${topic}&_page=1`)
            .then(async (res) => {
              await res.message.result.forEach((el) => {
                const image = [];

                if (el.announceImage.length) {
                  el.announceImage.map(async (el) => {
                    image.push(await this.$store.dispatch('getImageURL', el).then(async (res) => await res));
                  });
                } else {
                  image.push('https://th.bing.com/th/id/OIP.MvgjeMBHxcKil9_H4jQcvgAAAA?rs=1&pid=ImgDetMain');
                }

                el.urlImage = image;
              });
              console.log(res.message.result)
              this.items = res.message.result;
              //this.totalPage = res.message.total;
              this.playLoad = false;
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          console.log('New table');
        }
      },
      formatTofromNow(date) {
        // เวลาที่อัพเดท
        return dayjs().locale('th').to(dayjs(date));
      },
      formatToThaiTime(date) {
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').locale('th').format('DD-MMM-BBBB');
      },
    },
  };
</script>

<style></style>
